<template>
	<LayoutWide>
		<sub-nav-bar page="Marketing" />
		<div>
			<HeroBox :title="title" :subtitle="subtitle" :color="color" />
		</div>
		<div>
			<GetAllVisitableCustomerSearchComponent />
		</div>
	</LayoutWide>
</template>

<script>
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import useCustomerInfoState from '@/_srcv2/pages/business-development/customer-info/scripts/useCustomerInfoState.js'
import { ref, onMounted } from '@vue/composition-api'
import GetAllVisitableCustomerSearchComponent from '@/_srcv2/pages/business-development/customer-info/add-customer-info/components/GetAllVisitableCustomerSearchComponent.vue'

export default {
	name: 'AddCustomerInfo',
	components: {
		SubNavBar,
		HeroBox,
		GetAllVisitableCustomerSearchComponent,
	},
	setup() {
		const title = ref('Add or UpdateCustomer Info')
		const subtitle = ref(
			'You can add or update product list that customer consumes and some logs about customer,',
		)
		const color = ref('is-info')
		const {
			GetAllVisitableCustomerSearchComponentType,
			isAddCustomerInfoModalVisible,
			editorContentCustomerConsumedProducts,
			editorContentCustomerLog,
			companyID,
			customerID,
			customer,
		} = useCustomerInfoState()
		// ? ------------------------------------------------------------
		onMounted(() => {
			GetAllVisitableCustomerSearchComponentType.value = 'AddCustomerInfo'
			isAddCustomerInfoModalVisible.value = false
			editorContentCustomerConsumedProducts.value = ''
			editorContentCustomerLog.value = ''
			companyID.value = ''
			customerID.value = ''
			customer.value = ''
		})

		// todo -----------------------------------------------------------
		return {
			title,
			subtitle,
			color,
			isAddCustomerInfoModalVisible,
		}
	},
}
</script>

<style lang="scss" scoped></style>
